.block {
	color: #fff;
	background-color: rgb(35, 133, 161);

    h1 {
        font-weight: 900;
        font-size: 30px;

        @include breakpoint($min-tablet) {
            font-size: 50px;
        }

        @include breakpoint($min-desktop) {
            font-size: 100px;
        }
    }

    &.block-all-screen {
    	height: 100%;
    }
}

.block-background-image {
	position: absolute;
	width: 100%;
	height: 100%;
	background:  no-repeat center center;
    padding: 0;
    background-size: cover;
    opacity: 0.7;
}

.block-content {
	@include container;
}