$img-url: '/img/';
$font-path: '/fonts';

$primary-font: 'AvenirNext';

$susy: (
  columns: 12,
  container: 1170px
);

/* Media queries */
$max-mobile: max-width 480px;
$min-tablet: min-width 480px;
$min-desktop: min-width 769px;
$min-desktop-medium: min-width 1200px;
$min-desktop-big: min-width 1300px;

$primary-color: #FFCBB4;
$dark-color: #000;
