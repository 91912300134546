@include global-box-sizing(border-box);

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.container {
	@include container;
}

/* Provides vertical center */
.vcenter {
	@include vcenter;
}

/* Provides center alignment */
.text-center {
	@include text-center;
}

/* Clear floats */
.clearfix {
	@include clearfix;
}