/* TODO: Borrar el fichero al empezar el proyecto */

.coming-soon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    background-image: url(#{$img-url}bkg.jpg);
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position: center center;

    .logo {
        max-width: 50%;

        @include breakpoint($min-tablet) {
            margin-bottom: 40px;
        }
    }

    @include breakpoint($min-desktop) {
        background-size: 120% 130%;
    }
}

.coming-soon-content {
    @include center-content;
    width: 90%;
    max-width: 530px;
    text-align: center;
}

.coming-soon-content-text {
    font-family: $primary-font;
    font-size: 13px;
    line-height: 16px;
    margin-top: 20px;

    p:not(:last-child) {
        margin-bottom: 10px;
    }

    @include breakpoint($min-tablet) {
        font-size: 14px;
        line-height: 20px;
    }
}

.coming-soon-left,
.coming-soon-right {
    display: none;
    position: absolute;
    width: 155px;
    height: 100%;
    transition: .4s cubic-bezier(.17,.67,.1,1.27);
    transition-delay: .3s;
    text-align: center;

    @include breakpoint($min-desktop) {
        display: block;
    }
}

.coming-soon-left {
    left: -100%;

    &.is-loaded {
        left: 4%;

        @include breakpoint($min-desktop-big) {
            left: 11%;
        }
    }
}

.coming-soon-right {
    right: -100%;

    &.is-loaded {
        right: 4%;

        @include breakpoint($min-desktop-big) {
            right: 11%;
        }
    }
}

.coming-soon-contact {
    margin-top: 10px;
    font-size: 12px; 
    font-family: $primary-font;

    svg {
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        
        @include breakpoint($min-tablet) {
            width: 40px;
            height: 40px;
        }
    }

    p:not(:last-child) {
        margin-bottom: 10px;
    }

    a {
        font-size: 12px;
        line-height: 16px;
        color: $dark-color;
        text-decoration: none;

        @include breakpoint($min-tablet) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    @include breakpoint($min-tablet) {
        font-size: 16px;    
    }
}

.coming-soon-text-bottom {
    text-transform: uppercase;
    font-family: $primary-font;
    font-weight: 100;
    font-size: 23px;
    line-height: 28px;
    color: $primary-color;
    margin-top: 10px;

    @include breakpoint($min-tablet) {
        font-size: 30px;
        line-height: 33px;
        margin-top: 30px;
    }
}

.coming-soon-books-left {
    position: absolute;
    top: 10%;
    left: 10%;
}

.coming-soon-books-right {
    position: absolute;
    top: 10%;
    right: 10%;
}

.coming-soon-plant-left {
    position: absolute;
    top: 50%;
    left: 80%;
    display: none;

    @include breakpoint($min-desktop-medium) {
        display: block;
    }
}

.coming-soon-knifes-left {
    position: absolute;
    bottom: 5%;
    left: 10%;

    img:last-child {
        position: relative;
        left: 30px;
    }
}

.coming-soon-plant-right {
    position: absolute;
    top: 50%;
    right: 80%;  
    display: none;

    @include breakpoint($min-desktop-medium) {
        display: block;
    }
}

.coming-soon-knifes-right {
    position: absolute;
    bottom: 3%;
    right: 10%;

    img {
        &:first-child {
            position: relative;
            top: 30px;
            right: 0px;
        }

        &:last-child {
            position: relative;
            left: -80px;
            top: -5px;
        } 
    } 
}
