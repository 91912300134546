input[type=text],
input[type=email],
input[type=search],
input[type=password],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}
