@font-face {
    font-family: 'AvenirNext';
    src: url('#{$font-path}/AvenirNext-Regular.eot');
    src: url('#{$font-path}/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/AvenirNext-Regular.woff2') format('woff2'),
        url('#{$font-path}/AvenirNext-Regular.woff') format('woff'),
        url('#{$font-path}/AvenirNext-Regular.ttf') format('truetype'),
        url('#{$font-path}/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
